<template>
  <chart-component>
    <!-- Blurred box -->
    <!--    <ib-blurred-box-->
    <!--      v-if="subscriptionIsFreeTrial"-->
    <!--      @upgrade="showUpgradeDialog"-->
    <!--    />-->
    <!-- Blurred box -->
    <!-- Title -->
    <template #title>
      {{ $t('charts.cashFlowProjection') }}
    </template>
    <!-- /Title -->
    <!-- Period switcher -->
    <template #switcher>
      <timeline-period-switcher
        class="switcher"
        @change="switchChartPeriod($event)"
      />
    </template>
    <!-- Period switcher -->
    <!-- Chart -->
    <template #chart>
      <cash-flow-projection-chart
        hide-title
        :chart-animations="chartAnimations"
        :period="period"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template
      v-if="yearNetProfit && netProfitEndOfYearDateString"
      #right-side
    >
      <chart-component-right-side>
        <h5 class="m-0 mb-4">
          {{ $t('charts.netCashFlow') }}
        </h5>
        <div>
          <template v-for="(year, index) in numberOfYearsToShow">
            <year-info
              :key="year"
              class="mb-3"
              :avatar-text="`Y${year}`"
              :text="netProfitEndOfYearDateString[year]"
              :money="netCashFlow.values[index]"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- Right side -->
  </chart-component>
</template>

<script>
import CashFlowProjectionChart from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Charts/CashFlowProjectionChart'
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import MixinCalculations from '@/mixins/calculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import TimelinePeriodSwitcher from '@/components/Charts/Timeline/TimelinePeriodSwitcher'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'
import _find from 'lodash/find'

import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CashFlowProjection',

  components: {
    ChartComponentRightSide,
    YearInfo,
    ChartComponent,
    CashFlowProjectionChart,
    TimelinePeriodSwitcher
  },

  mixins: [MixinCurrencies, MixinCalculations, MixinUserSubscription],

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  computed: {
    ...mapState(['idea']),

    netCashFlow () {
      let data = null

      if (this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
          this.idea.storyMode.forecast.profitAndCashFlow.calculation.cashFlowTable.yearly
      ) {
        data = _find(this.idea.storyMode.forecast.profitAndCashFlow.calculation.cashFlowTable.yearly, ['nameValue', 'net-change-in-cash'])
      }

      return data
    },

    yearNetProfit () {
      let data = null
      if (this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
          this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit
      ) {
        data = this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit
      }

      return data
    },

    netProfitEndOfYearDateString () {
      let data = null

      if (
        this.idea.storyMode.forecast.profitAndCashFlow.calculation &&
        this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfitEndOfYearDateString
      ) {
        data = this.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfitEndOfYearDateString
      }

      return data
    }
  },

  mounted () {
    this.switchChartPeriod(this.period)
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    switchChartPeriod (period) {
      this.$set(this, 'period', period)
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    }
  }
}
</script>
